<template>
  <Page :hasBg="false" class="add-bank-card-view">
    <Navbar title="添加银行卡"></Navbar>
    <div class="box-border px-24 pb-72 mb-72">
      <!-- <div class="mt-8 text-center font-16">
        <Icon name="wdyhk" />
        <span>
          请绑定持卡人本人的银行卡
        </span>
      </div> -->
      <div class="flex items-center h-56 mt-8 font-16 leading-56">
        <Icon name="icon_common_toast_warning_yellow" />
        <span class="ml-8 text-warning tet-14">
          仅支持添加本人同名银行卡
        </span>
      </div>
      <div class="add-bank-card">
        <CellGroup class="add-bank-card">
          <Field class="mb-16" name="name" readonly v-model="formData.name" placeholder="请输入持卡人姓名">
            <template #label>
              <div class="flex items-center" @click="() => handlePreview(1)">
                <span class="mr-8">持卡人</span>
                <img
                  class="w-18 h-18"
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/xiaochengxu/icon_tips.svg"
                  alt=""
                />
              </div>
            </template>
          </Field>
          <Field
            class="mb-16"
            type="number"
            name="bankAccount"
            label="银行卡号"
            v-model="formData.bankAccount"
            placeholder="持卡人本人银行卡号"
            @input="handleChangeBankCode"
            @blur="handleChangeBankBlur"
            required
          >
            <template #right-icon>
              <div @click="handleSubmitBankCard">
                <Uploader>
                  <img
                    class="h-16 w-18"
                    src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/xiaochengxu/icon_scanning.svg"
                    alt=""
                  />
                </Uploader>
              </div>
            </template>
          </Field>
          <Field
            class="mb-16"
            name="bankName"
            label="发卡行"
            @click="previewBankList"
            v-model="formData.bankName"
            readonly
            placeholder="请选择发卡银行"
            is-link
            required
          >
          </Field>
          <Field
            v-model="formData.phone"
            name="phone"
            placeholder="请输入银行预留手机号"
            required
            @focus="scrollViewPosition"
          >
            <template #label>
              <div @click="() => handlePreview(2)"><Icon name="xxts" svgClass="mr-4" />手机号</div>
            </template>
          </Field>
        </CellGroup>
        <div class="btn-box" @click="handleAddBankCard">
          <Button type="primary" size="large">提交</Button>
        </div>
      </div>
      <Popup position="bottom" round :style="{ height: '30%' }" v-model="tipVisible">
        <div class="popcard pa-16">
          <div class="pop-title text_muted">{{ tipContent.title }}</div>
          <div class="mt-24 pop-content font-16">
            {{ tipContent.content }}
          </div>
          <div class="mt-20 text-center" @click="tipVisible = false">
            <Button type="primary" size="large">知道了</Button>
          </div>
        </div>
      </Popup>
      <Popup v-model="bankVisible" position="bottom">
        <Picker
          title="所属银行"
          show-toolbar
          :columns="allBankList"
          value-key="name"
          @confirm="onBankConfirm"
          @cancel="bankVisible = false"
        />
      </Popup>
    </div>
  </Page>
</template>
<script>
import { defineComponent, ref, reactive, onMounted, computed } from '@vue/composition-api';
import { Field, CellGroup, Popup, Button, Uploader, Toast, Picker } from 'vant';
import { apiAddBankCard, apiGetAllBank } from '@api/mine';
import { apiGetBankName } from '@api/extra';
import { apiOcrBankCard } from '@api/ocr';
import { useRouter } from '@/utils/compsitionHack';
import ClientApp from '@/client/index';
import { useBankStore } from '@/store/bank';
import { useUserStore } from '@/store/user';

const RoleTypeEnum = {
  Driver: 1, // 司机
  Fleet: 2 // 车对账
};

export default defineComponent({
  components: {
    CellGroup,
    Field,
    Popup,
    Button,
    Uploader,
    Toast,
    Picker
  },
  setup() {
    const tipVisible = ref(false);
    const bankVisible = ref(false);
    const bankStore = useBankStore();
    const allBankList = ref([]);
    const allowSelect = ref(false); // 是否允许用户手动选择银行卡 识别失败之前不开放
    const tipContent = ref({
      title: '持卡人说明',
      content: '为保障资金安全，仅支持添加司机本人同名银行卡。'
    });
    const userStore = useUserStore();
    const isDriver = computed(() => userStore.isDriver);
    const userInfo = computed(() => userStore.userInfo);
    const formData = reactive({
      name: userStore.realName, // 初始化回填用户名称
      bankAccount: '',
      bankName: '',
      bankCode: '',
      phone: '',
      // 1.5.8 标示身份
      role: isDriver.value ? RoleTypeEnum.Driver : RoleTypeEnum.Fleet,
      roleId: isDriver.value ? userInfo.value.driverId : userInfo.value.carrierId
    });
    const router = useRouter();
    const handlePreview = type => {
      tipVisible.value = true;
      tipContent.value =
        type === 1
          ? {
              title: '持卡人说明',
              content: '为保障资金安全，仅支持添加司机本人同名银行卡。'
            }
          : {
              title: '手机号说明',
              content:
                '银行预留手机号是你在办理该银行卡时所填写 的手机号。没有预留、忘记或已停用，请联系 银行客服处理。'
            };
    };

    const handleChangeBankCode = async e => {
      formData.bankAccount = e;
    };

    const handleChangeBankBlur = async e => {
      const bankCode = e.target.value;
      if (bankCode.length >= 12 || bankCode.length <= 20) {
        formData.bankCode = await getBnakNameFromThree(bankCode);
        allBankList.value.forEach(item => {
          if (formData.bankCode === item.charCode) {
            formData.bankName = item.name;
          } else {
            // 用户进行手动选择
            allowSelect.value = true;
          }
        });
      }
    };

    const getBnakNameFromThree = async bankAccount => {
      // 阿里接口获取第三方数据
      const res = await apiGetBankName({
        bankAccount
      });
      return new Promise(resolve => {
        resolve(res.bank || '');
      });
    };

    const handleSubmitBankCard = async () => {
      try {
        const imgResult = await ClientApp.openAlbum({ maxNum: 1, isCamera: true });
        const { url } = imgResult[0];
        const res = await apiOcrBankCard({ imageUrl: url });
        if (res?.data?.bankName) {
          formData.bankName = res.data.bankName;
          formData.bankAccount = res.data.bankCardNumber.replace(new RegExp(/( )/g), '');
          formData.bankCode = await getBnakNameFromThree(formData.bankAccount);
        } else {
          Toast.fail('银行卡号识别失败！');
          allowSelect.value = true;
        }
      } catch (err) {
        Toast.fail('银行卡号识别失败！');
      }
    };

    const handleAddBankCard = async () => {
      let allownext = true;
      for (let key in formData) {
        if (formData[key] === '') {
          allownext = false;
        }
      }
      if (allownext) {
        apiAddBankCard({ ...formData }).then(async () => {
          Toast.success('银行卡添加成功！');
          bankStore.queryBankCards();
          setTimeout(() => {
            router.back();
          }, 1000);
        });
      } else {
        Toast.fail('必填数据不能为空！');
      }
    };

    const previewBankList = () => {
      if (allowSelect.value) {
        bankVisible.value = true;
      }
    };

    const onBankConfirm = item => {
      formData.bankName = item.name;
      formData.bankCode = item.charCode;
      bankVisible.value = false;
    };

    const scrollViewPosition = () => {
      try {
        if (document.activeElement.tagName === 'INPUT') {
          // 安卓下可能有延迟
          setTimeout(() => {
            document.activeElement.scrollIntoViewIfNeeded(true);
          }, 100);
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      Toast.loading({
        duration: 300,
        message: '数据加载中...'
      });
      try {
        const res = await apiGetAllBank();
        if (res) {
          allBankList.value = res.data;
        }
      } finally {
        Toast.clear();
      }
    });
    return {
      tipVisible,
      bankVisible,
      tipContent,
      allBankList,
      allowSelect,
      formData,
      previewBankList,
      onBankConfirm,
      handlePreview,
      handleChangeBankCode,
      handleSubmitBankCard,
      handleChangeBankBlur,
      handleAddBankCard,
      scrollViewPosition
    };
  }
});
</script>
<style lang="less">
.add-bank-card {
  input.van-field__control {
    text-align: right;
  }
  [class*='van-hairline']::after {
    border: none;
  }
}
.add-bank-card-view {
  .page__content {
    background: #fff;
  }
}
</style>
