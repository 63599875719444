// 所有的第三方接口集成在这里
import { http } from '../utils/http';

export const apiGetBankName = params => {
  return http.get(
    `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${params.bankAccount}&cardBinCheck=true`,
    '',
    false
  );
};
