import { defineStore } from 'pinia';
import { getBankList } from '@api/mine';
import { getDataCache, setDataCache } from '@/core/cache/data';
import { USER_BANK_CARD_KEY } from '@/enums/cacheEnum';

export const useBankStore = defineStore({
  id: 'bank-card',
  state: () => ({
    cards: getDataCache(USER_BANK_CARD_KEY) || []
  }),
  actions: {
    async queryBankCards() {
      try {
        const res = await getBankList();
        const { data } = res;
        data && (this.cards = data);
        data && setDataCache(USER_BANK_CARD_KEY, data);
      } catch (error) {
        console.error(error);
      }
    }
  }
});
